#root {
  margin-bottom: 50px; 
}

html,
body {
  height: 100%;
  overflow-x: hidden;
  overflow-y: overlay;
  background: linear-gradient(
    rgba(108, 11, 203, 1) 25%,
    rgba(0, 0, 0, 0.85) 100%
  );
  font-family: "Samsung Sharp Sans Regular", sans-serif !important;
}

.MuiListItem-root .MuiTouchRipple-child {
  background-color: transparent;
}

.MuiRadio-colorSecondary.Mui-checked {
  color: #77FE39 !important;
}
.MuiGridList-root::-webkit-scrollbar { width: 0 !important } 
.MuiGridList-root {
  overflow: -moz-scrollbars-none; 
}
.MuiGridList-root  { -ms-overflow-style: none; }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
    -webkit-transition-delay: 9999s;
}
a {
  text-decoration: none !important;
}
.activeLink > span {
  border: "1px solid red"
}
.menu-wrapper--inner {
  transform: none;
}

.swipe {
  position: absolute;
}

.cardContainer {
  width: 90vw;
  max-width: 260px;
  height: 300px;
}

.card {
  position: relative;
  background-color: #fff;
  width: 80vw;
  max-width: 260px;
  height: 300px;
  box-shadow: 0px 0px 60px 0px rgba(0,0,0,0.30);
  border-radius: 20px;
  background-size: cover;
  background-position: center;
}

