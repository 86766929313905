code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* { margin: 0; padding: 0; }

@font-face {
  font-family: "Samsung Sharp Sans Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Samsung Sharp Sans Regular"),
    url("./fonts/samsungsharpsans.woff") format("woff");
}

@font-face {
  font-family: "Samsung Sharp Sans Medium";
  font-style: normal;
  font-weight: normal;
  src: local("Samsung Sharp Sans Medium"),
    url("./fonts/samsungsharpsans-medium.woff") format("woff");
}

@font-face {
  font-family: "Samsung Sharp Sans Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Samsung Sharp Sans Bold"),
    url("./fonts/samsungsharpsans-bold.woff") format("woff");
}
